.App{
min-height: 100vh;
background-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%);


}

.search-form{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar{
  
  width: 50%;
  border: none;
  padding: 10px;
  border-radius: 20px;
  box-shadow:  0 1px 6px rgb(32 33 36 / 28%); 
 /* google search bar */
}

.search-button{
  background: tomato;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border: none;
  color: white;
  border-radius: 20px;
  font-size: 1rem ;
  padding: 8px;

}

.recipes{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}


/* .cards{
    box-shadow: 0 4px 8px 0 #bbbafa  ;

  justify-content: space-around;
} */