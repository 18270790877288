.recipe{
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* background: #fff; */
    background-image: linear-gradient(120deg, #fafafa 0%, #ebedee 100%);

    align-items: center;
    min-width: 30%;
    max-width: 30%;
}

.container h1{
    color: tomato;
    padding-top: 20px;
    margin-top: 20px;
}

.container {
  padding: 2px 16px;
}

.recipe:hover{
 
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

}